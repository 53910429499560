import {ChargingStationSummary, IChargingSession, IChargingStation} from '../models/ChargingStation';
import {Contract} from '../models/Contract';
import {InstallationPartner} from '../models/InstallationPartner';
import {ChargingCardInvitation, OrganizationInvitation} from '../models/Invitation';
import {ILocation, ILocationSummary, IRoleType} from '../models/Location';
import {
  ICreateOrganizationRequest,
  IOrganization,
  IUpdateOrganizationRequest,
  IUpdateRegionRequest
} from '../models/Organization';
import {RFIDCard, RFIDCardProvider} from '../models/RFIDCard';
import {SplitBillingAgreement} from '../models/SplitBillingAgreement';
import {IUser} from '../models/User';
import {AppFeature, hasFeature} from '../utils/AppParameters';
import {None} from '../utils/Arrays';
import {Language} from '../utils/Internationalization';

import {APIClient} from './APIClient';
import {LatLonArea} from './UserAPI';

export class OrganizationsCache {
  private client: APIClient;
  private organizations?: Promise<IOrganization[]>;

  constructor(client: APIClient) {
    this.client = client;
  }

  getOrganizations(refresh: boolean) {
    if (refresh || this.organizations === undefined) {
      const url = `/api/v10/organizations`;
      this.organizations = this.client.doGet(url).then(organizations => {
        if (!Array.isArray(organizations)) return None;

        organizations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        return organizations;
      });
    }
    return this.organizations;
  }
}

export class OrganizationAPI {
  private client: APIClient;
  private cache: OrganizationsCache;

  constructor(client: APIClient, cache: OrganizationsCache) {
    this.client = client;
    this.cache = cache;
  }

  getAll(refresh: boolean = false): Promise<IOrganization[]> {
    return this.cache.getOrganizations(refresh);
  }

  getOrgsBySearchTerm(search = '*', page = 1, pageSize = 10): Promise<IOrganization[]> {
    const url = `/api/v10/organizations?search=${
      search === '' ? '*' : encodeURIComponent(search)
    }&page=${page}&pageSize=${pageSize}`;
    return this.client.doGet(url);
  }

  getById(id: number, withBillingInfo?: boolean): Promise<IOrganization> {
    return this.client.doGet(`/api/v10/organizations/${id}${withBillingInfo ? '?billing=true' : ''}`);
  }

  create(organization: ICreateOrganizationRequest): Promise<unknown> {
    const url = `/api/v10/organizations`;
    return this.client.doPost(url, organization);
  }

  update(organization: IUpdateOrganizationRequest): Promise<void> {
    const url = `/api/v10/organizations/${organization.id}`;
    return this.client.doPatch(url, organization);
  }

  delete(id: number): Promise<{success: boolean}> {
    const url = `/api/v10/organizations/${id}`;
    return this.client.doDeleteWithStatus(url);
  }

  updateRegion(organization: number, region: number, request: IUpdateRegionRequest): Promise<unknown> {
    const url = `/api/v10/organizations/${organization}/regions/${region}`;
    return this.client.doPatch(url, request);
  }

  getOrganizationUsers(id: number): Promise<IUser[]> {
    const url = `/api/v10/organizations/${id}/users`;
    return this.client.doGet(url);
  }

  getSuborganizations(id: number): Promise<IOrganization[]> {
    const url = `/api/v10/organizations/${id}/suborganizations`;
    return this.client.doGet(url);
  }

  addOrganizationUser(id: number, identifier: string, role?: IRoleType['id']): Promise<IUser[]> {
    const url = `/api/v10/organizations/${id}/users`;
    const data = {
      ...(hasFeature(AppFeature.SocialLogin)
        ? {emailAddresses: [identifier]}
        : {
            userNames: [identifier]
          }),
      role
    };

    return this.client.doPost<IUser[]>(url, data).then(result => result || None);
  }

  removeOrganizationUser(id: number, userId: number): Promise<unknown> {
    const url = `/api/v10/organizations/${id}/users/${userId}`;
    return this.client.doDelete(url);
  }

  resendOrganizationUserConfirmation(organizationId: number, userId: number): Promise<{sent: boolean} | undefined> {
    const url = `/api/v10/organizations/${organizationId}/users/${userId}/resendconfirmation`;
    return this.client.doPost(url, {});
  }

  confirmOrganizationUser(organizationId: number, userId: number, token: string): Promise<unknown> {
    const url = `/api/v10/organizations/${organizationId}/users/${userId}/confirm?token=${token}`;
    return this.client.doPost(url, {});
  }

  getLocations(id: number): Promise<ILocationSummary[]> {
    const url = `/api/v10/organizations/${id}/servicelocations`;
    return this.client.doGet(url);
  }

  getSplitBillingChargingStations(organizationId: number): Promise<IChargingStation[]> {
    const url = `/api/v10/organizations/${organizationId}/splitbilling/chargingstations`;
    return this.client.doGet(url);
  }

  getSplitBillingAgreements(organizationId: number): Promise<SplitBillingAgreement[]> {
    const url = `/api/v10/organizations/${organizationId}/splitbilling/agreements`;
    return this.client.doGet(url);
  }

  updateAllSplitBillingAgreements(
    organizationId: number,
    updates: SplitBillingAgreementBulkUpdateRequest
  ): Promise<SplitBillingAgreement[] | undefined> {
    const url = `/api/v10/organizations/${organizationId}/splitbilling/agreements`;
    return this.client.doPatch(url, updates);
  }

  getChargingStations(organizationId: number, area?: LatLonArea): Promise<ChargingStationSummary[]> {
    let url = `/api/v10/organizations/${organizationId}/chargingstations`;
    if (area) {
      url += `?area=${area.maxLat},${area.maxLon},${area.minLat},${area.minLon}`;
    }
    return this.client.doGet(url);
  }

  register(request: OrganizationRegistrationRequest) {
    const url = `/api/v10/organizations/register`;
    return this.client.doPost(url, request);
  }

  getSupportedCountryCodes(): Promise<string[]> {
    const url = `/api/v10/organizations/supportedcountries`;
    return this.client.doGet(url);
  }

  activate(organizationId: number, contract: Contract) {
    const url = `/api/v10/organizations/${organizationId}/activate-contract`;
    return this.client.doPost(url, {contract});
  }

  getPublicChargingTokens(organizationId: number): Promise<RFIDCard[]> {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens`;
    return this.client.doGet(url);
  }

  getPublicChargingTokenInvitations(organizationId: number): Promise<ChargingCardInvitation[]> {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens/invitations`;
    return this.client.doGet(url);
  }

  createPublicChargingToken(organizationId: number, token: CreateRFIDCardRequest) {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens`;
    return this.client.doPost(url, token);
  }

  updatePublicChargingToken(organizationId: number, id: number, updates: {name: string; employeeNumber?: string}) {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens/${id}`;
    return this.client.doPatch(url, updates);
  }

  deletePublicChargingToken(organizationId: number, id: number) {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens/${id}`;
    return this.client.doDelete(url);
  }

  checkCard(organizationId: number, rfid: string): Promise<CheckRFIDCardResponse | undefined> {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens/check/${rfid}`;
    return this.client.doGet(url);
  }

  orderCards(organizationId: number, cards: number) {
    const url = `/api/v10/organizations/${organizationId}/publicchargingtokens/ordercards`;
    return this.client.doPost(url, {cards});
  }

  getChargingSessions(organizationId: number, from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/organizations/${organizationId}/chargingsessions?range=${from},${to}&rangeMode=stop_or_start`;
    return this.client.doGet(url);
  }

  getCPOChargingSessions(organizationId: number, from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/organizations/${organizationId}/cpochargingsessions?range=${from},${to}&rangeMode=stop_or_start`;
    return this.client.doGet(url);
  }

  getCPOChargingSquares(organizationId: number, withStations?: boolean): Promise<ILocation[]> {
    let url = `/api/v10/organizations/${organizationId}/cpochargingparks`;
    if (withStations) {
      url = `${url}?withStations=true`;
    }
    return this.client.doGet(url);
  }

  searchInstallationPartners(query: string): Promise<InstallationPartner[]> {
    const url = `/api/v10/organizations/installationpartners/search?q=${query}`;
    return this.client.doGet(url);
  }

  getInvitations(organizationId: number): Promise<OrganizationInvitation[]> {
    const url = `/api/v10/organizations/${organizationId}/invitations`;
    return this.client.doGet(url);
  }
}

export interface CheckRFIDCardResponse {
  exists: boolean;
  userId?: number;
  publicCharging?: boolean;
}

interface CreateRFIDCardRequest {
  userId?: number;
  userName?: string;
  userEmailAddress?: string;
  createUser?: {
    userName?: string;
    firstName: string;
    emailAddress: string;
    password: string;
  };
  name: string;
  value: string;
  employeeNumber?: string;
  provider: RFIDCardProvider;
}

interface SplitBillingAgreementBulkUpdateRequest {
  refund?: {
    rate?: {
      value: number;
      currency: string;
    };
  };
}

interface OrganizationRegistrationRequest {
  organizationId?: number;
  company: {
    name: string;
    type: string;
    vat: string;
    representativeFirstName: string;
    representativeLastName: string;
    address: {
      streetAndNumber: string;
      city: string;
      postalCode: string;
      countryCode: string;
    };
    country: string;
    contact: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      language: Language;
    };
    billing: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      language: Language;
      address: {
        streetAndNumber: string;
        city: string;
        postalCode: string;
        countryCode: string;
      };

      accountHolder: string;
      bic: string;
      iban: string;
    };
    vatExempt?: boolean;
    vatExemptNote?: string;
  };
  admin?: {
    username?: string; // DEPRECATED
    firstName: string;
    password: string;
    email: string;
  };
  agreements: Contract[];
  hasAcceptedTermsAndConditions?: boolean;
}
