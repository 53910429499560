import dayjs from 'dayjs';

import {useEffect, useState, useCallback, useMemo} from 'react';

import {isAPIResponse} from '../api/APIClient';
import {useAppContext} from '../app/context';
import {ActivePeriod} from '../components/PeriodSelector';
import API from '../core/api';
import {
  IOrganizationActivationCodes,
  IActivation,
  IActivationCode,
  IOrganizationActivationCode,
  getActivationCodesForOrganization
} from '../models/ActivationCode';
import {IAppDashboard} from '../models/AppDashboard';
import {IAppliance} from '../models/Appliance';
import {IApplianceType} from '../models/ApplianceType';
import {AuthUser} from '../models/AuthUser';
import {ChargingDisplayImage} from '../models/ChargingDisplayImage';
import {
  IChargingSession,
  ChargingStationPaymentType,
  ChargingStation,
  IChargingStation
} from '../models/ChargingStation';
import {IChargingStationDiagnosticEvent} from '../models/ChargingStationDiagnostics';
import {ChargingStationModel} from '../models/ChargingStationModel';
import {EVChargingStationControllerState, CarChargerIECStatus} from '../models/ChargingStatus';
import {Device, IFirmwareVersion, IDevice} from '../models/Device';
import {IDeviceActivationHistoryEntry} from '../models/DeviceActivationHistory';
import {IDeviceConfigurationState, IDeviceConfiguration} from '../models/DeviceConfiguration';
import {IDeviceIncident} from '../models/DeviceIncident';
import {DeviceType} from '../models/DeviceType';
import {IHighLevelConfiguration} from '../models/HighLevelConfiguration';
import {ILoadChannel, LoadType} from '../models/Load';
import {
  ILocationSummary,
  ILocation,
  IGasWaterDevice,
  ILocationUser,
  IChildLocation,
  isLocalityParent,
  LocationFunctionType
} from '../models/Location';
import {IOrganization, IOrganizationRegion, ISearchField, ISearchFieldValue} from '../models/Organization';
import {IOverloadProtectionConfiguration} from '../models/OverloadProtection';
import {IRetentionPolicy} from '../models/RetentionPolicy';
import {ISmartDevice} from '../models/SmartDevice';
import {ISwitch} from '../models/Switch';
import {IThirdPartyCharger} from '../models/ThirdPartyCharger';
import {IUsageInterval, UsageUnit} from '../models/UsageValue';

import {None} from './Arrays';

import {Fetcher} from './Fetcher';
import {plural, T, singular} from './Internationalization';

export function useAppliances(
  fetch: Fetcher,
  locationId: number | undefined,
  showFindMe: boolean = false
): [IAppliance[] | undefined, () => void] {
  const [appliances, setAppliances] = useState<IAppliance[] | undefined>();

  const refresh = () => {
    locationId &&
      fetch('appliances', api => api.getAppliances(locationId, showFindMe), plural('appliance')).then(setAppliances);
  };
  useEffect(refresh, [fetch, locationId, showFindMe]);

  return [appliances, refresh];
}

export function useApplianceTypes(fetch: Fetcher): IApplianceType[] {
  const [appliancesTypes, setAppliancesTypes] = useState<IApplianceType[]>(None);
  useEffect(() => {
    fetch('applianceTypes', api => api.getApplianceTypes(), plural('applianceType')).then(types =>
      setAppliancesTypes(types)
    );
  }, [fetch]);
  return appliancesTypes;
}

function convertInterval(interval: IUsageInterval): IUsageInterval {
  const {timestamp, unit, value} = interval;
  switch (unit) {
    case UsageUnit.KiloWatt:
      return {
        timestamp,
        unit: UsageUnit.Watt,
        value: value === undefined ? undefined : value * 1000
      };
    case UsageUnit.KiloWattHour:
      return {
        timestamp,
        unit: UsageUnit.WattHour,
        value: value === undefined ? undefined : value * 1000
      };
    default:
      return interval;
  }
}

export function useSwitchReadings(
  fetch: Fetcher,
  locationId: number | undefined,
  applianceId: string | undefined,
  period: ActivePeriod | undefined
): [{readings: IUsageInterval[]; period: ActivePeriod} | undefined, () => void] {
  const [readings, setReadings] = useState<{
    readings: IUsageInterval[];
    period: ActivePeriod;
  }>();
  const refresh = useCallback(() => {
    if (locationId === undefined || applianceId === undefined || period === undefined) {
      setReadings(undefined);
      return;
    }

    fetch(
      'readings',
      api => api.getApplianceUsage(locationId, applianceId, period.from, period.to, period.interval),
      plural('switchReading').replace('{product}', 'Switch')
    ).then(items =>
      setReadings({
        readings: items.usage.intervals.map(interval => convertInterval(interval)),
        period
      })
    );
  }, [fetch, locationId, applianceId, period]);
  useEffect(refresh, [refresh]);
  return [readings, refresh];
}

export function useDeviceConfigurationHistory(fetch: Fetcher, device: IDeviceInfo | null | undefined) {
  const [history, setHistory] = useState<IDeviceConfigurationState[]>(None);
  const type = device && device.type;
  const serialNumber = device && device.serialNumber;

  useEffect(() => {
    if (!serialNumber || !type || !Device.isLegacy(type)) {
      setHistory(None);
      return;
    }

    fetch(
      'history',
      api => api.getDeviceConfigurationHistory(serialNumber),
      T('deviceConfigurationHistory.loading.history')
    ).then(setHistory);
  }, [fetch, type, serialNumber]);

  return history;
}

interface IDeviceInfo {
  locationId: number;
  type: DeviceType;
  serialNumber?: string;
}
export function useHistoricalDeviceInfo(
  fetch: Fetcher,
  location: ILocationSummary | ILocation | undefined
): IDeviceInfo | null | undefined {
  const locationId = location?.id;
  const deviceType = location?.deviceType;
  const serialNumber = location?.serialNumber;

  const [loadedInfo, setLoadedInfo] = useState<IDeviceInfo | null>();
  const locationInfo = useMemo(
    () =>
      locationId !== undefined &&
      ((deviceType !== undefined && serialNumber !== undefined) || location?.chargingStation !== undefined)
        ? {
            type: deviceType || DeviceType.EthernetConnect, // this device type will be absent for dual ultras
            serialNumber,
            locationId
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationId, deviceType, serialNumber]
  );

  useEffect(() => {
    if (!locationId) return undefined;

    if (serialNumber === undefined || deviceType === undefined) {
      setLoadedInfo(undefined);
      fetch(
        'historical',
        api => api.locations.getWithHistory(locationId),
        T('deviceConfigurationHistory.loading.historical')
      ).then(location => {
        const {serialNumber, deviceType} = location;
        if (serialNumber === undefined || deviceType === undefined) {
          setLoadedInfo(null);
        } else setLoadedInfo({type: deviceType, serialNumber, locationId});
      });
    } else {
      setLoadedInfo({type: deviceType, serialNumber, locationId});
    }
  }, [fetch, locationId, deviceType, serialNumber]);
  return locationInfo || loadedInfo;
}

export function useDeviceActivationHistory(fetch: Fetcher, serialNumber: string | undefined) {
  const [history, setHistory] = useState<IDeviceActivationHistoryEntry[]>(None);

  useEffect(() => {
    if (!serialNumber || serialNumber.length < 10) return;

    fetch('history', api => api.getDeviceActivationHistory(serialNumber, true), plural('deviceActivation')).then(
      setHistory
    );
  }, [fetch, serialNumber]);
  return history;
}

export function useDeviceActivationCode(
  fetch: Fetcher,
  serialNumber: string | undefined
): [IActivationCode | undefined, () => void] {
  const [activationCode, setActivationCode] = useState<IActivationCode>();
  const refresh = useCallback(() => {
    if (!serialNumber) {
      setActivationCode(undefined);
      return;
    }

    fetch('deviceActivationCode', api => api.getDeviceActivationCode(serialNumber), singular('deviceActivation')).then(
      setActivationCode
    );
  }, [fetch, serialNumber]);
  useEffect(refresh, [refresh]);
  return [activationCode, refresh];
}

function randomIECState(): EVChargingStationControllerState {
  const rnd = Math.random();
  // 25% aan het laden

  // charging 5
  // cable connected 3
  // charging finished 4
  // available 6
  // unavailable 2

  if (rnd < 0.4) {
    // available 30%
    return {current: CarChargerIECStatus.A2};
  } else if (rnd < 0.65) {
    // charging 25%
    return {current: CarChargerIECStatus.D2};
  } else if (rnd < 0.8) {
    // cable connected 15%
    return {current: CarChargerIECStatus.B1};
  } else {
    // charging finished
    return {current: CarChargerIECStatus.B2};
  }
}

function createDummyStation(index: number, name: string, stations: IChargingStation[]): ChargingStation {
  const serialNumber = (6000000010 + index).toString();
  const existing = stations.find(station => station.serialNumber === serialNumber);
  const result = new ChargingStation({
    model: ChargingStationModel.BaseCable,
    name,
    serialNumber,
    trackingSerialNumber: '',
    articleCode: 'EVB-xxx',
    active: true,
    available: Math.random() < 0.9,
    serviceLocation: existing ? existing.serviceLocation : undefined,
    tariffs: {
      fixed: 0,
      energy: 0.29,
      time: 0,
      currency: 'EUR'
    },
    paymentTypes: [ChargingStationPaymentType.App],
    maximumCapacity: 32,
    modules: [],
    connectionType: 'CASEC',
    cableLocked: false,
    visible: true,
    version: '',
    features: []
  });
  result.forcedState = {
    state: [randomIECState(), randomIECState()],
    power: 3600 + ((Math.random() * 18000) | 0)
  };
  result.forceOnline = true;
  return result;
}

export function useChargingStations(
  fetch: Fetcher,
  serviceLocationId: number | undefined,
  demoMode: boolean = false
): [ChargingStation[], (force?: boolean) => void] {
  const [chargingStations, setChargingStations] = useState<ChargingStation[]>(None);

  const refresh = useCallback(
    (force?: boolean) => {
      if (!serviceLocationId) {
        setChargingStations(None);
        return;
      }

      fetch(
        'chargingStations',
        api => api.chargingStations.getByLocation(serviceLocationId),
        plural('chargingStation')
      ).then(stations => {
        if (demoMode) {
          const fakeStations: ChargingStation[] = [];
          for (let i = 0; i < 20; i++) {
            fakeStations.push(createDummyStation(i, `Snowball${(i + 1).toString().padStart(2, '0')}`, stations));
          }
          setChargingStations(fakeStations);
        } else {
          setChargingStations(stations.map(station => new ChargingStation(station)));
        }
      });
    },
    [fetch, serviceLocationId, demoMode]
  );
  useEffect(refresh, [refresh]);

  return [chargingStations, refresh];
}

export function useChargingStationTransactions(
  fetch: Fetcher,
  serialNumber: string | undefined,
  period: ActivePeriod | undefined,
  demoMode: boolean
): [IChargingSession[], () => void] {
  const [transactions, setTransactions] = useState<IChargingSession[]>(None);

  const refresh = () => {
    if (!serialNumber || !period) return;

    fetch(
      'chargingSessions',
      api => api.getChargingStationSessions(serialNumber, period.from, period.to),
      plural('chargingSession')
    ).then(transactions => {
      if (demoMode) {
        transactions = transactions.filter(t => {
          if (t.to === undefined) return true;
          const duration = t.to - t.from;
          if (duration < 30 * 60 * 1000) return false;
          if (duration > 9 * 3600 * 1000) return false;
          return true;
        });
        transactions.forEach(transaction => {
          const power = 3.6 + Math.random() * 18;
          if (transaction.to) {
            const durationInHours = (transaction.to - transaction.from) / (1000 * 3600);
            const consumption = durationInHours * power;
            transaction.paymentType =
              Math.random() > 0.5 ? ChargingStationPaymentType.App : ChargingStationPaymentType.RFID;
            transaction.energy = consumption;
            transaction.cost = 0.29 * consumption;
          }
        });
      }

      setTransactions(transactions);
    });
  };
  useEffect(refresh, [fetch, serialNumber, period, demoMode]);

  return [transactions, refresh];
}

export function useChargingParkTransactions(
  fetch: Fetcher,
  locationId: number | undefined,
  period: ActivePeriod | undefined,
  demoMode?: boolean
): [IChargingSession[], () => void] {
  const [transactions, setTransactions] = useState<IChargingSession[]>(None);

  const refresh = useCallback(() => {
    if (locationId === undefined || period === undefined) return;

    fetch(
      'chargingSessions',
      api => api.getChargingParkSessions(locationId, period.from, period.to),
      plural('chargingSession')
    ).then(transactions => {
      if (demoMode) {
        transactions = transactions.filter(t => {
          if (t.to === undefined) return true;
          const duration = t.to - t.from;
          if (duration < 30 * 60 * 1000) return false;
          if (duration > 9 * 3600 * 1000) return false;
          return true;
        });
        transactions.forEach(transaction => {
          const power = 3.6 + Math.random() * 18;
          if (transaction.to) {
            const durationInHours = (transaction.to - transaction.from) / (1000 * 3600);
            const consumption = durationInHours * power;
            transaction.paymentType =
              Math.random() > 0.5 ? ChargingStationPaymentType.App : ChargingStationPaymentType.RFID;
            transaction.energy = consumption;
            transaction.cost = 0.29 * consumption;
          }
        });
      }

      setTransactions(transactions);
    });
  }, [fetch, locationId, period, demoMode]);
  useEffect(refresh, [refresh]);

  return [transactions, refresh];
}

export function useChargingStationDiagnostics(
  fetch: Fetcher,
  serialNumber: string | undefined,
  period: ActivePeriod | undefined
): [IChargingStationDiagnosticEvent[], () => void] {
  const [events, setEvents] = useState<IChargingStationDiagnosticEvent[]>([]);

  const refresh = useCallback(() => {
    if (serialNumber === undefined || period === undefined) {
      setEvents([]);
      return;
    }

    fetch(
      'diagnosticEvents',
      api => api.getChargingStationDiagnostics(serialNumber, period.from, period.to),
      'charging station diagnostics'
    ).then(setEvents);
  }, [serialNumber, period, fetch]);
  useEffect(refresh, [refresh]);

  return [events, refresh];
}

export function useLocationUsingAPI(
  api: API,
  id: number | undefined
): [ILocation | undefined, (force?: boolean) => void] {
  const [location, setLocation] = useState<ILocation | undefined>();
  const refresh = useCallback(
    (force?: boolean) => {
      if (id === undefined) {
        setLocation(undefined);
      } else {
        api.locations.get(id, force).then(setLocation);
      }
    },
    [api, id]
  );
  useEffect(refresh, [refresh]);
  return [location, refresh];
}

export function useLocation(
  fetch: Fetcher,
  id: number | undefined
): [ILocation | undefined, (force?: boolean) => void] {
  const [location, setLocation] = useState<ILocation | undefined>();
  const refresh = useCallback(
    (force?: boolean) => {
      if (id === undefined) {
        setLocation(undefined);
      } else {
        fetch('location', api => api.locations.get(id, force), singular('location')).then(setLocation);
      }
      // cleanup function:
      return () => {
        setLocation(undefined);
      };
    },
    [fetch, id]
  );
  useEffect(refresh, [refresh]);
  return [location, refresh];
}

export function useOrganizationsWithActivationCodes(fetch: Fetcher): [IOrganizationActivationCodes[], () => void] {
  const [organizations, setOrganizations] = useState<IOrganizationActivationCodes[]>(None);
  const load = useCallback(
    (refresh: boolean) => {
      fetch('organizations', api => api.activationCodes.getAll(refresh), plural('activationCode')).then(codes => {
        codes.organizations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setOrganizations(codes.organizations);
      });
    },
    [fetch]
  );
  useEffect(() => load(false), [load]);
  const refresh = useCallback(() => load(true), [load]);

  return [organizations, refresh];
}

export function useOrganizationActivationCodes(
  fetch: Fetcher,
  organizationId?: number
): [IOrganizationActivationCode[], () => void] {
  const [activationCodes, setActivationCodes] = useState<IOrganizationActivationCode[]>(None);
  const load = useCallback(
    (refresh: boolean) => {
      if (organizationId) {
        fetch(
          'activationCodes',
          api => api.activationCodes.getByOrganization(refresh, organizationId),
          plural('activationCode')
        ).then(setActivationCodes);
      } else {
        setActivationCodes(None);
      }
    },
    [fetch, organizationId]
  );
  useEffect(() => load(false), [load]);
  const refresh = useCallback(() => load(true), [load]);
  return [activationCodes, refresh];
}

export function useAllOrganizationActivationCodes(
  fetch: Fetcher,
  organizationId?: number
): [IOrganizationActivationCode[], () => void] {
  const {api} = useAppContext();
  const [activationCodes, setActivationCodes] = useState<IOrganizationActivationCode[]>(None);
  const load = useCallback(
    (refresh: boolean) => {
      let organization: Promise<IOrganization | undefined>;
      let ownActivationCodes: Promise<IOrganizationActivationCode[]>;
      if (organizationId) {
        organization = api.organizations.getById(organizationId);
        ownActivationCodes = fetch(
          'activationCodes',
          api => api.activationCodes.getByOrganization(refresh, organizationId),
          plural('activationCode')
        );
      } else {
        organization = Promise.resolve(undefined);
        ownActivationCodes = Promise.resolve(None);
      }

      let parentActivationCodes: Promise<IOrganizationActivationCode[]> = organization.then(org => {
        const parentId = org?.parentId;
        if (parentId) {
          return fetch(
            'activationCodes',
            api => api.activationCodes.getByOrganization(refresh, parentId),
            plural('activationCode')
          );
        } else {
          return Promise.resolve(None);
        }
      });

      Promise.all([organization, ownActivationCodes, parentActivationCodes])
        .then(([organization, own, parent]) => {
          return organization ? getActivationCodesForOrganization(own, parent, organization, false) : None;
        })
        .then(setActivationCodes);
    },
    [fetch, organizationId]
  );
  useEffect(() => load(false), [load]);
  const refresh = useCallback(() => load(true), [load]);
  return [activationCodes, refresh];
}

export function useActivationCodeHistory(fetch: Fetcher, code?: string): [IActivation[] | null, () => void] {
  const [items, setItems] = useState<IActivation[] | null>(null);
  const refresh = useCallback(() => {
    if (!code) {
      setItems(null);
      return;
    }

    fetch('history', api => api.activationCodes.getHistory(code), T('shipment.loading.history')).then(
      ({history: items}) => setItems(items)
    );
  }, [fetch, setItems, code]);
  useEffect(refresh, [refresh]);
  return [items, refresh];
}

export function useChargingStationForLocation(
  fetch: Fetcher,
  location: ILocationSummary | undefined
): [ChargingStation | undefined, (force?: boolean) => void] {
  const [station, setStation] = useState<ChargingStation>();
  const serialNumber = location && location.chargingStation && location.chargingStation.serialNumber;

  const refresh = useCallback(
    (force?: boolean) => {
      if (!serialNumber) {
        setStation(undefined);
        return;
      }

      fetch('station', api => api.chargingStations.getBySerial(serialNumber, force), singular('chargingStation')).then(
        station => setStation(new ChargingStation(station))
      );
    },
    [fetch, serialNumber]
  );
  useEffect(refresh, [refresh]);
  return [station, refresh];
}

export function useSmartDevices(fetch: Fetcher, locationId: number | undefined): [ISmartDevice[], () => void] {
  const [devices, setDevices] = useState<ISmartDevice[]>(None);
  const refresh = useCallback(() => {
    if (!locationId) {
      setDevices(None);
      return;
    }

    fetch('smartDevices', api => api.getSmartDevices(locationId), plural('smartDevice')).then(setDevices);
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [devices, refresh];
}

export function useAllSmartDevices(fetch: Fetcher, locationId: number | undefined): [ISmartDevice[], () => void] {
  const [devices, setDevices] = useState<ISmartDevice[]>(None);
  const refresh = useCallback(() => {
    if (!locationId) {
      setDevices(None);
      return;
    }

    fetch('smartDevices', api => api.getAllSmartDevices(locationId), plural('smartDevice')).then(setDevices);
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [devices, refresh];
}

export function useAllThirdPartyChargers(
  fetch: Fetcher,
  parentLocationId: number | undefined
): [IThirdPartyCharger[], () => void] {
  const [thirdPartyChargers, setThirdPartyChargers] = useState<IThirdPartyCharger[]>(None);
  const refresh = useCallback(() => {
    if (!parentLocationId) {
      setThirdPartyChargers(None);
      return;
    }

    fetch(
      'thirdPartyChargers',
      api => api.chargingStations.getThirdPartyChargers(parentLocationId),
      plural('thirdPartyCharger')
    ).then(setThirdPartyChargers);
  }, [fetch, parentLocationId]);
  useEffect(refresh, [refresh]);
  return [thirdPartyChargers, refresh];
}

export function useLocationIdWithGrid(fetch: Fetcher, location: ILocationSummary | undefined) {
  const parentId =
    location && (location.parentId || (isLocalityParent(location.functionType) ? location.id : undefined));
  const [parentHighLevelConfig] = useHighLevelConfiguration(fetch, parentId);
  if (location === undefined) return undefined;
  if (location.parentId === undefined && !isLocalityParent(location.functionType)) {
    return location.id;
  }
  if (parentHighLevelConfig === undefined) return undefined;

  const grid = parentHighLevelConfig.measurements
    .filter(load => load.type === LoadType.Grid || load.type == LoadType.VirtualGrid)
    .find(load => load.serviceLocationId === location.id);
  return grid && grid.serviceLocationId;
}

export function useOverloadProtectionConfiguration(
  fetch: Fetcher,
  locationId: number | undefined
): [IOverloadProtectionConfiguration | undefined, () => void] {
  const [configuration, setConfiguration] = useState<IOverloadProtectionConfiguration>();
  const refresh = useCallback(() => {
    if (locationId === undefined) {
      setConfiguration(undefined);
      return;
    }

    fetch(
      'overloadProtection',
      api => api.getOverloadProtection(locationId),
      T('smartDevices.loading.overloadProtection')
    ).then(setConfiguration);
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [configuration, refresh];
}

export function useHighLevelConfiguration(
  fetch: Fetcher,
  locationId: number | undefined
): [IHighLevelConfiguration | undefined, () => void] {
  const [configuration, setConfiguration] = useState<IHighLevelConfiguration>();
  const load = useCallback(
    (forceRefresh: boolean) => {
      if (!locationId) return;

      fetch(
        'configuration',
        api => api.getHighLevelConfiguration(locationId, forceRefresh),
        T('phasorDisplay.loading.configuration')
      ).then(setConfiguration);
    },
    [fetch, locationId]
  );
  useEffect(() => load(false), [load]);
  const refresh = useCallback(() => load(true), [load]);
  return [configuration, refresh];
}

export function useSensors(fetch: Fetcher, locationId: number | undefined) {
  const [sensors, setSensors] = useState<IGasWaterDevice[]>();
  useEffect(() => {
    if (!locationId) {
      setSensors(undefined);
      return;
    }

    fetch('sensors', api => api.getSensors(locationId), plural('sensor')).then(setSensors);
  }, [fetch, locationId]);
  return sensors;
}

export function useOrganizations(fetch: Fetcher): [IOrganization[], (refresh?: boolean) => void, boolean] {
  const [organizations, setOrganizations] = useState<IOrganization[]>(None);
  const [loading, setLoading] = useState(false);
  const refresh = useCallback(
    (refresh?: boolean) => {
      setLoading(true);
      fetch('organizations', api => api.organizations.getAll(refresh), plural('organization'))
        .then(setOrganizations)
        .finally(() => setLoading(false));
    },
    [fetch]
  );
  useEffect(refresh, [refresh]);
  return [organizations, refresh, loading];
}

export function useOrganization(
  fetch: Fetcher,
  organizationId: number | undefined
): [IOrganization | undefined, (force: boolean) => void] {
  const [organization, setOrganization] = useState<IOrganization>();
  const refresh = useCallback(() => {
    if (organizationId === undefined) {
      setOrganization(undefined);
      return;
    }

    fetch(
      'organization',
      api =>
        api.organizations.getById(organizationId).catch(error => {
          if (isAPIResponse(error) && error.statusCode === 404) {
            return undefined;
          } else {
            throw error;
          }
        }),
      singular('organization')
    ).then(setOrganization);
  }, [fetch, organizationId]);
  useEffect(refresh, [refresh]);
  return [organization, refresh];
}

export function useAvailableOrganizations(
  fetch: Fetcher,
  user: AuthUser,
  searchTerm = '',
  page = 1,
  pageSize = 10,
  active = true
): [IOrganization[], (refresh?: boolean) => void] {
  const [organizations, setOrganizations] = useState<IOrganization[]>(None);
  const refresh = useCallback(
    (refresh?: boolean) => {
      if (!active) {
        setOrganizations(None);
        return;
      }

      fetch(
        'organizations',
        api => {
          if (user.isServiceDesk()) {
            return api.organizations.getOrgsBySearchTerm(searchTerm, page, pageSize);
          } else {
            return api.getUserOrganizations(user.userId, refresh);
          }
        },
        plural('organization')
      ).then(setOrganizations);
    },
    [fetch, user, page, pageSize, searchTerm, active]
  );
  useEffect(refresh, [refresh]);
  return [organizations, refresh];
}

export function useLegacyDeviceQuestions(fetch: Fetcher, locationId: number | undefined) {
  const [questions, setQuestions] = useState<unknown>();
  useEffect(() => {
    if (!locationId) {
      setQuestions(undefined);
      return;
    }

    fetch(
      'userQuestions',
      api => api.getUserQuestions(locationId),
      T('deviceConfigurationLegacy.loading.userSurvey')
    ).then(setQuestions);
  }, [fetch, locationId]);
  return questions;
}

export function useLegacyDeviceConfiguration(
  fetch: Fetcher,
  serialNumber: string | undefined
): IDeviceConfiguration | undefined {
  const [configuration, setConfiguration] = useState<IDeviceConfiguration>();
  useEffect(() => {
    if (!serialNumber) {
      setConfiguration(undefined);
      return;
    }

    fetch(
      'configuration',
      api => api.getCurrentConfiguration(serialNumber),
      T('deviceConfigurationLegacy.loading.configuration')
    ).then(setConfiguration);
  }, [fetch, serialNumber]);
  return configuration;
}

interface IDeviceIncidentWithTimestamp extends IDeviceIncident {
  timestampMillis: number;
}

export function useDeviceIncidents(
  fetch: Fetcher,
  serialNumber: string | undefined
): [IDeviceIncidentWithTimestamp[], () => void] {
  const [incidents, setIncidents] = useState<IDeviceIncidentWithTimestamp[]>(None);
  const refresh = useCallback(() => {
    if (!serialNumber) {
      setIncidents(None);
      return;
    }

    fetch('incidents', api => api.getDeviceIncidents(serialNumber), plural('incident')).then(incidents => {
      setIncidents(
        // Nov 26, 2021, 4:39:21 PM
        incidents.map(incident => ({
          ...incident,
          timestampMillis: dayjs(incident.timestamp, 'MMM D, YYYY, h:m:s A', 'en').valueOf()
        }))
      );
    });
  }, [fetch, serialNumber]);
  useEffect(refresh, [refresh]);
  return [incidents, refresh];
}

export function useRetentionPolicy(
  fetch: Fetcher,
  locationId: number | undefined
): [IRetentionPolicy | undefined, () => void] {
  const [result, setResult] = useState<IRetentionPolicy>();
  const refresh = useCallback(() => {
    if (!locationId) {
      setResult(undefined);
      return;
    }

    fetch('retention', api => api.locations.get(locationId), T('electricityValues.loading.historicalLocation'))
      .then(x => x.retention)
      .then(setResult);
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [result, refresh];
}

export function useLoadRetentionPolicy(
  fetch: Fetcher,
  locationId: number | undefined
): Promise<IRetentionPolicy | undefined> {
  return useMemo(() => {
    if (!locationId) return Promise.resolve(undefined);

    return fetch(
      'retention',
      api => api.locations.get(locationId).then(x => x.retention),
      T('electricityValues.loading.historicalLocation')
    );
  }, [fetch, locationId]);
}

export function useLocationActivationCode(
  fetch: Fetcher,
  locationId: number | undefined
): [IActivationCode | undefined, () => void] {
  const [result, setResult] = useState<IActivationCode>();
  const refresh = useCallback(() => {
    if (!locationId) {
      setResult(undefined);
      return;
    }
    fetch('activationCode', api => api.getLocationActivationCode(locationId), singular('activationCode')).then(
      setResult
    );
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [result, refresh];
}

export function useChannels(fetch: Fetcher, locationId: number | undefined, deviceType: DeviceType | undefined) {
  const [result, setResult] = useState<ILoadChannel[]>(None);
  useEffect(() => {
    if (!locationId || !deviceType) {
      setResult(None);
      return;
    }

    fetch('channels', api => api.getChannels(locationId, deviceType), plural('channel')).then(setResult);
  }, [fetch, locationId, deviceType]);
  return result;
}

export function useLocationFirmwares(fetch: Fetcher, locationId: number | undefined) {
  const [result, setResult] = useState<{[key: string]: IFirmwareVersion[]}>({});
  useEffect(() => {
    if (!locationId) {
      setResult({});
      return;
    }

    fetch('firmwares', api => api.locations.getFirmwareVersions(locationId), T('firmware.loading.firmwareList')).then(
      firmwares => setResult(firmwares || {})
    );
  }, [fetch, locationId]);
  return result;
}

export function useLocationUsers(fetch: Fetcher, locationId: number | undefined): [ILocationUser[], () => void] {
  const [users, setUsers] = useState<ILocationUser[]>(None);
  const refresh = useCallback(() => {
    if (!locationId) {
      setUsers(None);
      return;
    }

    fetch('users', api => api.locations.getUsers(locationId), plural('user')).then(setUsers);
  }, [locationId, fetch]);
  useEffect(refresh, [refresh]);
  return [users, refresh];
}

export function useOrganizationRegions(
  fetch: Fetcher,
  organizationId: number | undefined
): [IOrganizationRegion[], (regions: IOrganizationRegion[]) => void] {
  const [regions, setRegions] = useState<IOrganizationRegion[]>(None);
  useEffect(() => {
    if (!organizationId) {
      setRegions(None);
      return;
    }

    fetch('regions', api => api.getOrganizationRegions(organizationId), plural('region')).then(setRegions);
  }, [fetch, organizationId]);
  return [regions, setRegions];
}

export function useOrganizationSearchFields(fetch: Fetcher, organizationId: number | undefined): ISearchField[] {
  const [searchFields, setSearchFields] = useState<ISearchField[]>(None);
  useEffect(() => {
    if (!organizationId) {
      setSearchFields(None);
      return;
    }

    fetch('searchFields', api => api.getOrganizationSearchFields(organizationId), plural('searchField')).then(fields =>
      setSearchFields(fields || [])
    );
  }, [fetch, organizationId]);
  return searchFields;
}

export function useLocationSearchFieldValues(
  api: API,
  locationId: number | undefined
): [ISearchFieldValue[], () => void] {
  const [values, setValues] = useState<ISearchFieldValue[]>(None);
  const refresh = useCallback(() => {
    locationId && api.getLocationSearchFieldValues(locationId).then(setValues);
  }, [api, locationId]);
  useEffect(refresh, [refresh]);
  return [values, refresh];
}

export function useLocationModules(
  fetch: Fetcher,
  locationId: number | undefined,
  includeInternal?: boolean
): [IDevice[] | undefined, () => void] {
  const [devices, setDevices] = useState<IDevice[]>();
  const refresh = useCallback(() => {
    if (!locationId) {
      setDevices(undefined);
      return;
    }

    fetch('modules', api => api.locations.getModules(locationId, includeInternal), plural('module')).then(setDevices);
  }, [fetch, locationId, includeInternal]);
  useEffect(refresh, [refresh]);
  return [devices, refresh];
}

export function useChildLocations(
  fetch: Fetcher,
  location: ILocationSummary | ILocation | undefined
): [IChildLocation[], () => void] {
  const [locations, setLocations] = useState<IChildLocation[]>(None);
  const locationId = location && location.id;
  const functionType = location && location.functionType;

  const refresh = useCallback(() => {
    if (locationId === undefined || !isLocalityParent(functionType)) {
      setLocations(None);
      return;
    }

    fetch('childs', api => api.locations.getChildren(locationId), plural('location')).then(setLocations);
  }, [fetch, locationId, functionType]);
  useEffect(refresh, [refresh]);
  return [locations, refresh];
}

export function useLocationSwitches(
  fetch: Fetcher,
  locationId: number | undefined
): [ISwitch[], (force?: boolean) => void] {
  const [switches, setSwitches] = useState<ISwitch[]>(None);
  const refresh = useCallback(
    (force?: boolean) => {
      if (!locationId) {
        setSwitches(None);
        return;
      }
      fetch('switches', api => api.getSwitches(locationId, force), plural('switchLeaf')).then(setSwitches);
    },
    [fetch, locationId]
  );
  useEffect(refresh, [refresh]);
  return [switches, refresh];
}

export function useAvailableChargingDisplayImages(
  fetch: Fetcher,
  serialNumber: string | undefined,
  serviceLocationId: number | undefined,
  locationFunctionType: LocationFunctionType
): [ChargingDisplayImage[], () => void, boolean] {
  const [chargingDisplayImages, setChargingDisplayImages] = useState<ChargingDisplayImage[]>(None);
  const [loading, setLoading] = useState(false);
  const loadChargingParkImages = locationFunctionType === 'CHARGINGPARK';
  const loadChargingStationImages = !!(!loadChargingParkImages && serialNumber);
  const refresh = useCallback(() => {
    if (!loadChargingParkImages && !loadChargingStationImages) {
      setChargingDisplayImages(None);
      return;
    }
    setLoading(true);
    fetch(
      'chargingDisplayImages',
      api => {
        if (loadChargingParkImages) {
          return api.getChargingParkImages(serviceLocationId!);
        } else {
          return api.getChargingStationImages(serialNumber!);
        }
      },
      plural('chargingDisplayImage')
    )
      .then(setChargingDisplayImages)
      .finally(() => setLoading(false));
  }, [fetch, loadChargingParkImages, loadChargingStationImages, serialNumber, serviceLocationId]);
  useEffect(refresh, [refresh]);
  return [chargingDisplayImages, refresh, loading];
}

const NO_DASHBOARD: IAppDashboard = {id: 0, cards: []};
export function useAppDashboard(fetch: Fetcher, locationId: number | undefined): [IAppDashboard, () => void] {
  const [dashboard, setDashboard] = useState<IAppDashboard>(NO_DASHBOARD);
  const refresh = useCallback(() => {
    if (locationId === undefined) {
      setDashboard(NO_DASHBOARD);
      return;
    }

    fetch('dashboard', api => api.getAppDashboard(locationId), singular('configuration')).then(setDashboard);
  }, [fetch, locationId]);
  useEffect(refresh, [refresh]);
  return [dashboard, refresh];
}
